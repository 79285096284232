import 'bootstrap/dist/js/bootstrap.bundle';
import '@popperjs/core';
import 'bootstrap-select';
import 'bootstrap-slider';
import 'bootstrap-slider/dist/css/bootstrap-slider.css';
import { CountUp } from 'countup.js';
import '@fancyapps/fancybox/dist/jquery.fancybox.js';
import '@fancyapps/fancybox/dist/jquery.fancybox.css';
import { Loader } from '@googlemaps/js-api-loader';
import '@googlemaps/markerclusterer';
import 'jquery-hoverintent';
import 'jquery-inview';
import 'jquery-match-height';
import 'jquery-parallax.js';
import 'jquery-smooth-scroll';
import 'object-fit-images';
import 'superfish';
import 'swiper';
import 'waypoints/lib/noframework.waypoints.min';

import "../Scss/styles.scss";

$(document).ready(function () {
    $('.equal-height').matchHeight();
});


//yield-calculator start
$(document).ready(function () {
    if( $(".container-yield-calculator").length > 0 ) {
        (function (funcName, baseObj) {
            funcName = funcName || "docReady";
            baseObj = baseObj || window;
            var readyList = [];
            var readyFired = false;
            var readyEventHandlersInstalled = false;

            function ready() {
                if (!readyFired) {
                    readyFired = true;
                    for (var i = 0; i < readyList.length; i++) {
                        readyList[i].fn.call(window, readyList[i].ctx);
                    }
                    readyList = [];
                }
            }

            function readyStateChange() {
                if (document.readyState === "complete") {
                    ready();
                }
            }

            baseObj[funcName] = function (callback, context) {
                if (typeof callback !== "function") {
                    throw new TypeError("callback for docReady(fn) must be a function");
                }
                if (readyFired) {
                    setTimeout(function () {
                        callback(context);
                    }, 1);
                    return;
                } else {
                    readyList.push({fn: callback, ctx: context});
                }
                if (document.readyState === "complete") {
                    setTimeout(ready, 1);
                } else if (!readyEventHandlersInstalled) {
                    if (document.addEventListener) {
                        document.addEventListener("DOMContentLoaded", ready, false);
                        window.addEventListener("load", ready, false);
                    } else {
                        document.attachEvent("onreadystatechange", readyStateChange);
                        window.attachEvent("onload", ready);
                    }
                    readyEventHandlersInstalled = true;
                }
            }
        })("docReady", window);

        docReady(function (context) {
            $('[data-toggle="tooltip"]').tooltip();

            $(function () {
                $('[data-toggle="tooltip"]').tooltip()
            }),

                $(function () {
                    $('[data-toggle="popover"]').popover({container: 'body'})
                }),

                $("#tax").slider({tooltip: 'hide', precision: 4});
            $("#commission").slider({tooltip: 'hide', precision: 4});
            $("#notary").slider({tooltip: 'hide', precision: 4});
            $("#rentPerNight").slider({tooltip: 'hide', precision: 4});
            $("#maintenanceCostsPerSquareMeter").slider({tooltip: 'hide', precision: 4});
            $("#utilization").slider({tooltip: 'hide', precision: 4});

            calculateCostsAndYield();

            $("#purchasePrice, #squareMeters").on("keyup", function (changeEvt) {
                calculateCostsAndYield();
            });


            $("#notary, #tax, #commission, #rentPerNight, #maintenanceCostsPerSquareMeter, #utilization").on("slide slideStop", function (slideEvt) {

                var target = slideEvt.target.id;
                var sliderValue = slideEvt.value;
                sliderValue = sliderValue.toLocaleString();

                if (target == 'rentPerNight' || target == 'maintenanceCostsPerSquareMeter') {
                    var unit = ',-€';
                } else {
                    var unit = '%';
                }
                $("#" + target + "SliderVal").text(sliderValue + unit);
                calculateCostsAndYield();

            });

        });

        function calculateCostsAndYield() {
            var squareMeters = parseInt($("#squareMeters").val());
            var utilization = parseFloat($("#utilization").val()) / 100;
            var maintenancePerMeters = parseInt($("#maintenanceCostsPerSquareMeter").val());
            var maintenanceCosts = squareMeters * maintenancePerMeters;

            var rentPerNight = $("#rentPerNight").val();
            var rentPerYear = 365 * utilization * rentPerNight;

            var netIncomeTotal = rentPerYear - maintenanceCosts;
            var netIncomeTotalString = netIncomeTotal.toLocaleString();

            var purchasePrice = parseInt($("#purchasePrice").val());
            var costsTotal = purchasePrice + purchasePrice * parseFloat($("#tax").val()) / 100 + purchasePrice * parseFloat($("#commission").val()) / 100 + purchasePrice * parseFloat($("#notary").val()) / 100;
            var costsTotalString = costsTotal.toLocaleString();

            if (purchasePrice > 10000 && squareMeters > 10) {
                $('#costsTotal').text(costsTotalString + ',-€');
                $('#incomeTotal').text(netIncomeTotalString + '€');

                var yield2 = Math.round((netIncomeTotal / costsTotal) * 10000) / 100;

                $('#maintenancePerYear').text('(' + maintenanceCosts.toLocaleString() + ',-€ / Jahr)');
                $('#yield').text(yield2.toLocaleString({'minimumFractionDigits': 2, 'style': 'decimal'}) + '%');
            } else {
                $('#maintenancePerYear').text('');
                $('#yield').text('Bitte Kaufpreis und Größe angeben!');
                return true;
            }
        }
    }
});
//yield-calculator end

document.addEventListener("DOMContentLoaded", function() {

    let links = document.querySelectorAll('a');
    links.forEach(function(item){
        if (item.getAttribute('data-mailto-token') != null) {
            item.innerHTML = item.innerHTML.replace('@~@', '@');
        }
    });

});




(function ($, window) {

    /**
     * Enable thumbnail support for Swiper
     * @param swiper (pass swiper element)
     * @param settings (pass custom options)
     */
    window.swiperThumbs = function (swiper, settings) {

        /**
         * Loop over swiper instances
         */
        $(swiper).each(function () {

            var _this = this;

            /**
             * Default settings
             */
            var options = {
                element: 'swiper-thumbnails',
                activeClass: 'is-active'
            }

            /**
             * Merge user settings and default settings
             */
            $.extend(options, settings);

            /**
             * Helper vars
             */
            var element = $('.' + options.element);

            /**
             * Get real activeIndex
             * @returns {*}
             */
            var realIndex = function (index) {
                // if index doesn't exist set index to activeIndex of swiper
                if (index === undefined) index = _this.activeIndex;

                // Check if swiper instance has loop before getting real index
                if (_this.params.loop) {
                    return parseInt(_this.slides.eq(index).attr('data-swiper-slide-index'));
                } else {
                    return index;
                }
            }

            var app = {

                init: function () {
                    app.bindUIevents();
                    app.updateActiveClasses(realIndex(_this.activeIndex));
                },

                bindUIevents: function () {
                    /**
                     * Bind click events to thumbs
                     */
                    element.children().each(function () {
                        $(this).on('click', function () {

                            // Get clicked index
                            var index = parseInt($(this).index());

                            // Get difference between item clicked and current real active index.
                            var difference = (index - realIndex());

                            // Move to slide that makes sense for the user by
                            // checking what the current active slide is and adding the difference
                            // this makes sure the swiper moves to a natural direction the user expects.
                            app.moveToSlide(_this.activeIndex + difference);
                        })
                    })

                    /**
                     * Update thumbs on slideChange
                     */
                    _this.on('slideChangeStart', function (swiper) {
                        app.updateActiveClasses(realIndex())
                    });
                },

                moveToSlide: function (index) {
                    _this.slideTo(index);
                },

                updateActiveClasses: function (index) {
                    element.children().removeClass(options.activeClass);
                    element.children().eq(index).addClass(options.activeClass);
                }
            }

            app.init();

        });

    };

}(jQuery, window));

var browserName = navigator.appName;
var browserVer = parseInt(navigator.appVersion);
var version = "";
var msie4 = (browserName == "Microsoft Internet Explorer" && browserVer >= 4);
if ((browserName == "Netscape" && browserVer >= 3) || msie4 || browserName == "Konqueror" || browserName == "Opera") {
    version = "n3";
} else {
    version = "n2";
}
function blurLink(theObject) {
    if (msie4) {
        theObject.blur();
    }
}
function decryptCharcode(n, start, end, offset) {
    n = n + offset;
    if (offset > 0 && n > end) {
        n = start + (n - end - 1);
    } else if (offset < 0 && n < start) {
        n = end - (start - n - 1);
    }
    return String.fromCharCode(n);
}
function decryptString(enc, offset) {
    var dec = "";
    var len = enc.length;
    for (var i = 0; i < len; i++) {
        var n = enc.charCodeAt(i);
        if (n >= 0x2B && n <= 0x3A) {
            dec += decryptCharcode(n, 0x2B, 0x3A, offset);
        } else if (n >= 0x40 && n <= 0x5A) {
            dec += decryptCharcode(n, 0x40, 0x5A, offset);
        } else if (n >= 0x61 && n <= 0x7A) {
            dec += decryptCharcode(n, 0x61, 0x7A, offset);
        } else {
            dec += enc.charAt(i);
        }
    }
    return dec;
}

function linkTo_UnCryptMailto(s) {
    location.href = decryptString(s, -2);
}

var $body = $("body");
var $document = $(document);
var $window = $(window);
var windowWidth = $window.width();

function initializeStickyHeader() {
    var waypoint = new Waypoint({
        element: document.getElementById('nav-waypoint'),
        handler: function (direction) {
            $('body').toggleClass('header-background', direction === 'down');
        }
    });
}


function initializeSmoothScroll() {
    $('body').smoothScroll({
        delegateSelector: '.scrolldown a',
        offset: -150,
        speed: 600
    });
}

function inititalizeSFMenu() {
    function getWindowWidth() {
        var windowWidth = 0;
        if (typeof(window.innerWidth) === 'number') {
            windowWidth = window.innerWidth;
        } else {
            if (document.documentElement && document.documentElement.clientWidth) {
                windowWidth = document.documentElement.clientWidth;
            } else {
                if (document.body && document.body.clientWidth) {
                    windowWidth = document.body.clientWidth;
                }
            }
        }
        return windowWidth;
    }

    if (getWindowWidth() > 991) {
        $('#main-nav ul .sf-mega ul').css('display', 'block');
    }

    var mainnavOptions = {
        popUpSelector: 'ul,.sf-mega',
        delay: 800,
        speed: 'normal',
        speedOut: 'normal',
        animation: {opacity:'show'},
        animationOut:  {opacity:'hide'},
        cssArrows: false
    }

    $('#main-nav > .menu > ul').superfish(mainnavOptions);

    $(window).resize(function () {
        $('body').removeClass('main-nav-active');
    });
}

function initializeFixedHeader() {
    $(window).scroll(function () {
        if ($(document).scrollTop() > 100) {
            $('body').addClass('shrinked-header');
        } else {
            $('body').removeClass('shrinked-header');
        }
    });
}


function initializeSearchBox() {
    var $mainnav = $("#main-nav");
    $(document).on('click', function (event) {
        if (!$(event.target).closest('#header-search').length) {
            $body.toggleClass('header-search-open', false);
        }
        if (!$(event.target).closest('#quick-contact').length) {
            $body.toggleClass('quick-contact-open', false);
        }
        if (!$(event.target).closest('#main-nav').length) {
            $body.toggleClass('main-nav-active', false);
        }
    });

    $(".header-search-trigger").click(function (e) {
        $body.toggleClass('header-search-open');
        if ($body.hasClass('header-search-open')) {
            $('#header-search').find('input').focus();
        }
        e.stopPropagation();
        e.preventDefault();
    });

    $(".quick-contact-trigger, .quick-contact-trigger-box").click(function (e) {
        $body.toggleClass('quick-contact-open');
        e.stopPropagation();
        e.preventDefault();
    });

    $("#trigger").click(function (e) {
        $body.toggleClass('main-nav-active');
        e.stopPropagation();
        e.preventDefault();
    });

}


function initializeSwiper() {
    var mySwiper = new Swiper('.slider-slide', {
        pagination: '.swiper-pagination',
        paginationClickable: true,
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        centeredSlides: true,
        speed: $('.slider-slide').data('speed'),
        autoplay: $('.slider-slide').data('autoplay'),
        autoplayDisableOnInteraction: false,
        loop: true
    });
    var mySwiper = new Swiper('.slider-fade', {
        pagination: '.swiper-pagination',
        paginationClickable: true,
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        centeredSlides: true,
        speed: $('.slider-fade').data('speed'),
        autoplay: $('.slider-fade').data('autoplay'),
        autoplayDisableOnInteraction: false,
        effect: 'fade',
        loop: true
    });

    var galleryTop = new Swiper('.gallery-top', {
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        pagination: '.swiper-pagination-thumbs',
        paginationClickable: true,
        preloadImages: false,
        lazyLoading: true,
        lazyLoadingInPrevNext: true,
        lazyLoadingInPrevNextAmount: 1
    });

    if ($(".swiper-thumbnails").length) {
        swiperThumbs(galleryTop, {
            element: 'swiper-thumbnails',
            activeClass: 'is-active'
        });
    }

    var galleryTopZoom = new Swiper('.gallery-top-zoom', {
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        pagination: '.swiper-pagination-thumbs',
        paginationClickable: true,
        preloadImages: false,
        lazyLoading: true,
        lazyLoadingInPrevNext: true,
        lazyLoadingInPrevNextAmount: 1,
        zoom: true
    });

    if ($(".swiper-thumbnails-zoom").length) {
        swiperThumbs(galleryTopZoom, {
            element: 'swiper-thumbnails-zoom',
            activeClass: 'is-active'
        });
    }
}

function initializeOpenLocations() {
    $(".location-title-toggle").click(function () {
        $(this).closest('.location-box').toggleClass("show-location");
    });
}

function initializeToogleClass() {
    $(".memorize").click(function () {
        $(".memorize").toggleClass("added-fav");
    });
}

function initializeCounter() {
    var options = {
        useEasing: true,
        easingFn: null,
        useGrouping: true,
        separator: '.',
        decimal: '.'
    };

    $(".counting-number").each(function () {
        $(this).one('inview', function (event, isInView, visiblePartX, visiblePartY) {
            if (isInView) {
                var numAnim = new CountUp(this, $(this).data("end-val"), options);
                numAnim.start()
            }
        }).html(0);
    });
}

function initMap() {

    loader
        .load()
        .then((google) => {
            new google.maps.Map(document.getElementById("googlemap"), mapOptions);
        })
        .catch(e => {
            // do something
        });
/*
    $maps = $("#googlemap");
    if ($maps.length > 0) {
        $maps.css({height: 400});
        locations = {
            "Standort": {lat: 53.586756, lng: 10.124702}
        };

        var bounds = new google.maps.LatLngBounds();
        var infowindow = new google.maps.InfoWindow();

        var map = new google.maps.Map(document.getElementById('googlemap'), {
            scrollwheel: false,
            center: new google.maps.LatLng(53.586756, 10.124702),
            zoom: 17
        });

        for (l in locations) {
            marker = new google.maps.Marker({
                position: locations[l],
                map: map,
                title: l
            });

            marker.addListener('click', function () {
                infowindow.setContent(this.getTitle());
                infowindow.open(map, this);
            });

            bounds.extend(marker.getPosition());
        }

        //map.fitBounds(bounds);
    }

 */
}

function setSizeOfHeaderMap($maps) {
    if ($(window).width() > 991) {
        $maps.css({height: 515});
    };

    if ($(window).width() < 991) {
        $maps.css({height: 340});
    };
    if ($(window).width() < 768) {
        $maps.css({height: 300});
    };
    if ($(window).width() < 480) {
        $maps.css({height: 200});
    };
}

function resizeHeaderMap(id, map, bounds, markerList) {
    setSizeOfHeaderMap($("#"+id));

    var i;
    for (i = 0; i < markerList.length; i++) {
        bounds.extend(markerList[i].getPosition());
    }
    map.fitBounds(bounds);
    map.setCenter(bounds.getCenter(), 13);
}


function initHeaderMap(id, locations) {

    const loader = new Loader({
        apiKey: "AIzaSyBnXsp5U8pZC38Z_iXE6211ttiKJHxvaes",
        version: "weekly",
        libraries: ["places"]
    });

    loader
        .load()
        .then((google) => {

        setSizeOfHeaderMap($("#"+id));

        var headerMapMarker = [],
            bounds = new google.maps.LatLngBounds(),
            infowindow = new google.maps.InfoWindow(),
            mapOptions = {
                scrollwheel: false,
                center: new google.maps.LatLng(53.904344,9.9444553),
                zoom: 13,
                maxZoom: 17,
                styles: [
                    {
                        "featureType": "all",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "saturation": 36
                            },
                            {
                                "color": "#333333"
                            },
                            {
                                "lightness": 40
                            }
                        ]
                    },
                    {
                        "featureType": "all",
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "visibility": "on"
                            },
                            {
                                "color": "#ffffff"
                            },
                            {
                                "lightness": 16
                            }
                        ]
                    },
                    {
                        "featureType": "all",
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#fefefe"
                            },
                            {
                                "lightness": 20
                            }
                        ]
                    },
                    {
                        "featureType": "administrative",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "color": "#fefefe"
                            },
                            {
                                "lightness": 17
                            },
                            {
                                "weight": 1.2
                            }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#f5f5f5"
                            },
                            {
                                "lightness": 20
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "visibility": "simplified"
                            },
                            {
                                "color": "#beaf8c"
                            },
                            {
                                "lightness": "90"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.business",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.government",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.medical",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#beaf8c"
                            },
                            {
                                "visibility": "on"
                            },
                            {
                                "weight": "0.8"
                            },
                            {
                                "lightness": "0"
                            },
                            {
                                "gamma": "1"
                            },
                            {
                                "saturation": "0"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#beaf8c"
                            },
                            {
                                "lightness": "23"
                            },
                            {
                                "gamma": "1.00"
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#beaf8c"
                            },
                            {
                                "lightness": "0"
                            },
                            {
                                "visibility": "simplified"
                            },
                            {
                                "gamma": "1"
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "visibility": "simplified"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#1e3252"
                            },
                            {
                                "lightness": "0"
                            },
                            {
                                "gamma": "1"
                            }
                        ]
                    }
                ]
            };
        var map = new google.maps.Map(document.getElementById(id), mapOptions);
        var geocoder = new google.maps.Geocoder();
        var i;
        for (i = 0; i < locations.length; i++) {
            placeMarker(map, locations[i], infowindow, geocoder, bounds, headerMapMarker);
        }

        $(window).resize(function () {
            resizeHeaderMap(id, map, bounds, headerMapMarker);
        });

        if (window.headerMapCluster!==undefined && window.headerMapCluster===true) {
            var markerCluster = new MarkerClusterer(map, headerMapMarker, {imagePath: '/typo3conf/ext/sparda/Resources/Public/Img/m', maxZoom: 10, zoomOnClick: true});
            //markerCluster.setGridSize(1);
            //markerCluster.setMaxZoom(1);
        }


    })
    .catch(e => {
        // do something
    });
}



function placeMarker(map, location, infowindow, geocoder, bounds, markerList) {
    if (location.lat !== undefined && location.lng !== undefined) {
        addMarker(map, location, infowindow, { lat: location.lat, lng: location.lng }, bounds, markerList)
    } else if (location.address !== undefined) {
        geocoder.geocode({'address': location.address}, function(results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
                // console.log(results[0].geometry.location.lat(), results[0].geometry.location.lng());
                // console.log(location.title, location.test.lat, location.test.lng);
                addMarker(map, location, infowindow, results[0].geometry.location, bounds, markerList)
            } else {
                if (status == google.maps.GeocoderStatus.OVER_QUERY_LIMIT) {
                    setTimeout(placeMarker.bind(null, map, location, infowindow, geocoder, bounds, markerList), 1000);
                } else {
                    console.log('Geocode was not successful for the following reason: ' + status);
                }
            }
        });
    }
}

function addMarker(map, location, infowindow, position, bounds, markerList) {
    var zIndex = 100,
        shape = null;

    if (location.icon !== undefined) {
        var image = {
            url: location.icon
        };

        if (location.size !== undefined) {
            image.size = new google.maps.Size(location.size.x, location.size.y);
        }

        if (location.origin !== undefined) {
            image.origin = new google.maps.Point(location.origin.x, location.origin.y);
        }

        if (location.anchor !== undefined) {
            image.anchor = new google.maps.Point(location.anchor.x, location.anchor.y);
        }

        if (location.shape !== undefined) {
            shape = location.shape;
        }
    }

    if (location.zIndex !== undefined) {
        zIndex = location.zIndex;
    }

    if (location.count !== undefined) {
        var marker = new google.maps.Marker({
            position: position,
            map: map,
            icon: image,
            draggable: false,
            shape: shape,
            raiseOnDrag: false,
            animation: google.maps.Animation.DROP,
            zIndex: zIndex,
            label: {
                text: String(location.count),
                color: 'black'
            }
        });
    } else {
        var marker = new google.maps.Marker({
            position: position,
            map: map,
            icon: image,
            draggable: false,
            shape: shape,
            raiseOnDrag: false,
            animation: google.maps.Animation.DROP,
            zIndex: zIndex
        });
    }

    bounds.extend(marker.getPosition());

    google.maps.event.addListener(marker, 'click', (function (map, marker, location) {
        return function () {
            if (location.offset !== undefined) {
                infowindow.setOptions({pixelOffset: new google.maps.Size(location.offset.x, location.offset.y)});
            } else {
                infowindow.setOptions({pixelOffset: new google.maps.Size(0, 0)});
            }
            infowindow.setContent('<span style="color:#000">'+location.text+'</span>');
            infowindow.open(map, marker);
        }
    })(map, marker, location));

    map.fitBounds(bounds);
    map.setCenter(bounds.getCenter(), 13);

    markerList.push(marker);
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};


Number.prototype.formatMoney = function(c, d, t){
    var n = this,
        c = isNaN(c = Math.abs(c)) ? 2 : c,
        d = d == undefined ? "." : d,
        t = t == undefined ? "," : t,
        s = n < 0 ? "-" : "",
        i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
        j = (j = i.length) > 3 ? j % 3 : 0;
    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
};

function initializeSearch() {
    var aFilterIsSet = false;
    $(".bs-slider").each(function(){
        var $t = $(this);
        var unitValue = $t.data("unit");

        var $slider = $t.slider({});
        console.log($slider);
        console.log($t.val(),$t.val().split(","));
        var valueMinMax = $t.val().split(",");
        var isFiltered = parseFloat(valueMinMax[0]) > parseFloat($t.data("slider-min")) || parseFloat(valueMinMax[1]) < parseFloat($t.data("slider-max"));
        aFilterIsSet |= isFiltered;

        var $slider_value = $t.parent().find(".slider-value");
        var updateSliderValue = function (value) {
            var minMaxValues = value.toString().split(",");
            if (unitValue == "€") {
                for(var i in minMaxValues) {
                    minMaxValues[i] = parseFloat(minMaxValues[i]).formatMoney(0, ",", ".");
                }
            }
            $slider_value.text(minMaxValues[0] + unitValue + ' - ' +  minMaxValues[1] + unitValue);
        };
        updateSliderValue($t.val());

        $slider.on("slide", function(slideEvent) {
            debounce(updateSliderValue(slideEvent.value), 100);
        });
    });

    var $additionalFilters = $("#additional_filters");
    var $toggleAdditionalFilters = $("#toggle-additional-filters");
    $toggleAdditionalFilters.on("click", function (e) {
        e.preventDefault();
        $additionalFilters.toggle();
    });
    $additionalFilters.toggle(aFilterIsSet === 1);

    var doSubmit = debounce(function () {
        var $t = $(this);
        if($t.val() == lastSortBy) {
            $(".directions:not(:checked)").prop("checked", true);
        } else {
            $("#direction_asc").prop("checked", true);
        }

        $(this).closest("form").submit();
    }, 300);

    $(".tx-sparda-immobilien #immobilien-search select").on("change", doSubmit);
    $(".tx-sparda-immobilien #immobilien-search .slider-handle").on("mouseup", doSubmit);
    $(".tx-sparda-immobilien #immobilien-search input").on("click", doSubmit);
}

$(document).ready(function () {
    var headerMapId = "header_map",
        headerMap = $("#"+headerMapId);

    /*tn_tracking();*/

    //initMap();
    initializeSearch();
    initializeFixedHeader();
    initializeSmoothScroll();
    inititalizeSFMenu();
    initializeSearchBox();
    initializeSwiper();
    initializeCounter();
    initializeToogleClass();

    if (headerMap.length>0) {
        initHeaderMap(headerMapId, headerMapLocations);
    }

    if ($(window).width() > 767) {

    }

    if ($(window).width() < 768) {
        initializeOpenLocations()
    }

    if ($(window).width() < 991) {

    }

    // Scroll to top link
    $(".top-link a").on("click", function (event) {
        event.preventDefault();
        $("html, body").animate({scrollTop: 0}, 1000);
    });

    $( ".immo-directsearch-content #immobilien-search" ).submit(function( event ) {
        var context = "Direktsuche";
        var type = $( "#immo-type option:selected" ).text();
        var object = $( "#immo-object option:selected" ).text();
        //ga('send', 'event', 'Immo '+context, 'suchen', type+' | '+object);
    });

    $( ".tx-sparda-immobilien #immobilien-search" ).submit(function( event ) {
        var context = "Detailsuche";
        var type = $( "#immo-type option:selected" ).text();
        var object = $( "#immo-object option:selected" ).text();
        var use = $( "#immo-use option:selected" ).text();
        var place = $( "#immo-place option:selected" ).text();
        //ga('send', 'event', 'Immo '+context, 'suchen', type+' | '+object+' | '+use+' | '+place);
    });

    $('.panel-accordion').on('shown.bs.collapse', function() {
        $(window).trigger("resize");
    }).on('hidden.bs.collapse', function() {
        $(window).trigger("resize");
    });

    $('.off-canvas-links li').on('click', 'a', function (event) {
        var link = this;

        event.preventDefault();
        $.smoothScroll({
            offset: -150,
            scrollTarget: link.hash,
            speed: 600
        });
        return false;
    });

    $('.off-canvas-nav-trigger').on('click', function () {
        $body.toggleClass('off-canvas-menu-active');
    });

    if ($('#pageoverlay').length > 0) {
        var hasShownOverlay = sessionStorage.getItem(olid);
        if (!hasShownOverlay) {
            sessionStorage.setItem(olid, 'ja');
            setTimeout(function () {
                $.fancybox.open(
                    [
                        {
                            src: overlayPageUID,
                            type: 'ajax',
                            filter: '#overlay-content',
                            toolbar  : false,
                            smallBtn : true,
                            iframe : {
                                preload : true,
                                css : {
                                    width : '600px'
                                }
                            }
                        }
                    ]
                );
            }, 500);

        } else {
            sessionStorage.removeItem(olid);
        }
    }
});